<script setup>
import useClients from '@/hooks/useClients';
import debounce from "lodash/debounce";
import pageTitle, { setPageTitle } from '@/utils/pageTitle';
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import useTransportOrders from '@/hooks/useTransportOrders';
import { useToast } from 'vue-toastification';
import useWarehouses from '@/hooks/useWarehouses';
import {TRANSPORT_ORDERS_ROUTE} from "@/constants/constants.js";
import { dayMonthYearFormat } from '@/utils/dateUtils';

const isPickUpAppointmentRequired = ref(false);
const isDropOffAppointmentRequired = ref(false);
const pickUpMenu = ref(false);
const dropOffMenu = ref(false);
const fileList = ref([]);
const pickUpDate = ref(new Date(Date.now()));
const dropOffDate = ref(new Date(Date.now()));
const { clients, fetchClients } = useClients();
const { fetchCargoTypes, fetchAdditionalServices, cargoTypes, additionalServices, createTransportOrder } = useTransportOrders();
const { warehouses, fetchWarehouses } = useWarehouses();
const router = useRouter(); 
const toast = useToast();

// order details
const transport_order = ref({
    client_id: "",
    warehouse_id: "",
    pick_address: "",
    pick_date: "",
    pick_dock_hours: "",
    pick_appointment_contact_info: "",
    pick_additional_services: [],
    drop_address: "",
    drop_date: "",
    drop_dock_hours: "",
    drop_appointment_contact_info: "",
    drop_additional_services: [],
    po_number: "",
    other_ref_number: "",
    cargo_type: "",
    cargo_qty: "",
    cargo_weight: "",
    notes: "",
})

onMounted(async() => {
    setPageTitle("Transportation Orders // New");
    await fetchClients(clientUrlParams);
    await fetchWarehouses();
    await fetchCargoTypes();
    await fetchAdditionalServices();
})

const clientUrlParams = {
    filter: {
        name: {
        type: "contains",
        filter: "",
        },
    },
    sort: [
        {
        sort: "asc",
        colId: "name",
        },
    ],
    limit: 500,
};

const debouncedFetchClients = debounce(async (query) => {
    clientUrlParams.filter.name.filter = query;
    await fetchClients(clientUrlParams);
}, 300);

const onChange = (e) => {
    console.log(e.target.files);
    fileList.value = [...e.target.files];
}
const remove =(index) => {
    fileList.value.splice(index, 1);
}

const validateRequiredFields = () => {
    if (!transport_order.value.client_id) {
        toast.error("Please select a client.");
        return true;
    }
    if (!transport_order.value.warehouse_id) {
        toast.error("Please select a warehouse.");
        return true;
    }
    if (!transport_order.value.po_number) {
        toast.error("PO number is required.");
        return true;
    }
    if (fileList.value.length === 0) {
        toast.error("At least one file is required.");
        return true;
    }
    if (!transport_order.value.pick_address) {
        toast.error("Pick up address is required.");
        return true;
    }
    if (!transport_order.value.drop_address) {
        toast.error("Drop off address is required.");
        return true;
    }
    if (!transport_order.value.pick_dock_hours) {
        toast.error("Pick up dock hours is required.");
        return true;
    }
    if (!transport_order.value.drop_dock_hours) {
        toast.error("Drop off dock hours is required.");
        return true;
    }
    if (!transport_order.value.cargo_type) {
        toast.error("Please select a cargo type.");
        return true;
    }
}
const addTransportOrder = async () => {
    if (validateRequiredFields()) return;
    const fd = new FormData();                
    fileList.value.forEach((f) => {
        console.log(f, "files");
        fd.append('file', f)
    });
    // fd.append("order_id", 1);
    fd.append("client_id", transport_order.value.client_id);
    fd.append("warehouse_id", transport_order.value.warehouse_id);
    fd.append("po_number", transport_order.value.po_number);
    fd.append("customer_ref", transport_order.value.other_ref_number);
    fd.append("pick_address", transport_order.value.pick_address);
    fd.append("pick_appt", transport_order.value.pick_date);
    fd.append("pick_dock_hours", transport_order.value.pick_dock_hours);
    fd.append("drop_address", transport_order.value.drop_address);
    fd.append("drop_appt", transport_order.value.drop_date);
    fd.append("drop_dock_hours", transport_order.value.drop_dock_hours);
    fd.append("cargo_type", transport_order.value.cargo_type);
    fd.append("cargo_qty", transport_order.value.cargo_qty);
    fd.append("cargo_weight", transport_order.value.cargo_weight);
    fd.append("notes", transport_order.value.notes);
    // fd.append("pick_additional_services", transport_order.value.pick_additional_services);
    // fd.append("drop_additional_services", transport_order.value.drop_additional_services);
    fd.append("pick_appt_required", isPickUpAppointmentRequired.value);
    fd.append("drop_appt_required", isDropOffAppointmentRequired.value);
    if (isPickUpAppointmentRequired.value) {
        fd.append("pick_appt_contact", transport_order.value.pick_appointment_contact_info);
    }
    if (isDropOffAppointmentRequired.value) {
        fd.append("drop_appt_contact", transport_order.value.drop_appointment_contact_info);
    }
    
    const response = await createTransportOrder(fd);
    if (response) {
        router.push(`/${TRANSPORT_ORDERS_ROUTE}`);
    }
}
watch(pickUpDate, (newValue) => {
    if (newValue) {
        transport_order.value.pick_date = dayMonthYearFormat(newValue);
    }
});

watch(dropOffDate, (newValue) => {
    if (newValue) {
        transport_order.value.drop_date = dayMonthYearFormat(newValue);
    }
});
</script>

<template>
    <v-card-title>{{ pageTitle }}</v-card-title>
    <div class="pa-2 pa-sm-5 pa-md-10 w-100 h-100">
        <v-card class="pa-4">
            <v-autocomplete
                v-model="transport_order.client_id"
                density="compact"
                :items="clients"
                @update:search="(query) => debouncedFetchClients(query)"
                item-title="name"
                item-value="id"
                hide-details
                variant="outlined"
            >
                <template v-slot:label>
                    Client <span class="text-red">*</span>
                </template>
            </v-autocomplete>
            <v-autocomplete
                class="mt-4"
                v-model="transport_order.warehouse_id"
                density="compact"
                :items="warehouses?.data"
                item-title="name"
                item-value="id"
                hide-details
                variant="outlined"
            >
                <template v-slot:label>
                    Warehouse <span class="text-red">*</span>
                </template>
            </v-autocomplete>
        </v-card>

        <!-- PICKUP AND DROP OFF -->
        <v-row class="mt-4">
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title class="border-b-sm">Pickup</v-card-title>
                    <v-card-text class="mt-4">
                        <v-text-field
                            v-model="transport_order.pick_address"
                            density="compact"
                            variant="outlined"
                            hide-details
                            placeholder="Enter your address..."
                        >   
                            <template v-slot:label>
                                Pickup Address <span class="text-red">*</span>
                            </template>
                        </v-text-field>
                        <div class="d-flex justify-space-between ga-4 mt-4">
                            <v-text-field
                                v-model="transport_order.pick_date"
                                class="w-50"
                                density="compact"
                                hide-details
                                id="pickup-menu-activator"
                                label="Date"
                                placeholder="dd/mm/yyyy"
                                variant="outlined"
                                readonly
                            ></v-text-field>
                                <v-menu
                                    v-model="pickUpMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    activator="#pickup-menu-activator"
                                >
                                    <v-date-picker
                                        v-model="pickUpDate"
                                        @update:modelValue="() => { pickUpMenu = false }"
                                        show-adjacent-months
                                    ></v-date-picker>
                                </v-menu>
                            <v-text-field
                                v-model="transport_order.pick_dock_hours"
                                class="w-50"
                                density="compact"
                                variant="outlined"
                                hide-details
                                placeholder="Ex: 8am - 5pm"
                            >
                                <template v-slot:label>
                                    Dock Hours <span class="text-red">*</span>
                                </template>
                            </v-text-field>
                        </div>
                        <div class="d-sm-flex justify-space-between ga-4 mt-4">
                            <v-checkbox
                                v-model="isPickUpAppointmentRequired"
                                class="w-100 w-sm-50"
                                density="compact"
                                variant="outlined"
                                label="Appointment Required"
                                hide-details
                                color="primary"
                            ></v-checkbox>
                            <v-textarea
                                v-if="isPickUpAppointmentRequired"
                                v-model="transport_order.pick_appointment_contact_info"
                                rows="3"
                                class="w-100 w-sm-50"
                                density="compact"
                                variant="outlined"
                                hide-details
                                label="Appointment Contact Info"
                            ></v-textarea>
                        </div>

                        <v-autocomplete
                            v-model="transport_order.pick_additional_services"
                            multiple
                            chips
                            class="mt-4"
                            density="compact"
                            :items="additionalServices"
                            hide-details
                            variant="outlined"
                            label="Additional Services"
                        />
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title class="border-b-sm">Drop Off</v-card-title>
                    <v-card-text class="mt-4">
                        <v-text-field
                            v-model="transport_order.drop_address"
                            density="compact"
                            variant="outlined"
                            hide-details
                            placeholder="Enter your address..."
                        >
                            <template v-slot:label>
                                Drop Off Address <span class="text-red">*</span>
                            </template>
                        </v-text-field>
                        <div class="d-flex justify-space-between ga-4 mt-4">
                            <v-text-field
                                v-model="transport_order.drop_date"
                                class="w-50"
                                density="compact"
                                hide-details
                                id="dropoff-menu-activator"
                                label="Date"
                                placeholder="dd/mm/yyyy"
                                variant="outlined"
                                readonly
                            ></v-text-field>
                                <v-menu
                                    v-model="dropOffMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    activator="#dropoff-menu-activator"
                                >
                                    <v-date-picker
                                        v-model="dropOffDate"
                                        @update:modelValue="() => { dropOffMenu = false }"
                                        show-adjacent-months
                                    ></v-date-picker>
                                </v-menu>
                            <v-text-field
                                v-model="transport_order.drop_dock_hours"
                                class="w-50"
                                density="compact"
                                variant="outlined"
                                hide-details
                                placeholder="Ex: 8am - 5pm"
                            >
                                <template v-slot:label>
                                    Dock Hours <span class="text-red">*</span>
                                </template>
                            </v-text-field>
                        </div>
                        <div class="d-sm-flex justify-space-between ga-4 mt-4">
                            <v-checkbox
                                v-model="isDropOffAppointmentRequired"
                                class="w-100 w-sm-50"
                                density="compact"
                                variant="outlined"
                                label="Appointment Required"
                                hide-details
                                color="primary"
                            ></v-checkbox>
                            <v-textarea
                                v-if="isDropOffAppointmentRequired"
                                v-model="transport_order.drop_appointment_contact_info"
                                rows="3"
                                class="w-100 w-sm-50"
                                density="compact"
                                variant="outlined"
                                hide-details
                                label="Appointment Contact Info"
                            ></v-textarea>
                        </div>

                        <v-autocomplete
                            v-model="transport_order.drop_additional_services"
                            multiple
                            chips
                            class="mt-4"
                            density="compact"
                            :items="additionalServices"
                            hide-details
                            variant="outlined"
                            label="Additional Services"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- CARGO DETAILS -->
        <v-card class="mt-4">
            <v-card-title class="border-b-sm">Cargo Details</v-card-title>
            <v-card-text class="mt-4">
                <div class="d-flex justify-space-between ga-4 mt-4">
                    <v-text-field
                        v-model="transport_order.po_number"
                        class="w-50"
                        density="compact"
                        variant="outlined"
                        hide-details
                    >
                        <template v-slot:label>
                            PO Number <span class="text-red">*</span>
                        </template>
                    </v-text-field>
                    <v-text-field
                        v-model="transport_order.other_ref_number"
                        class="w-50"
                        density="compact"
                        variant="outlined"
                        hide-details
                        label="Other Ref. Number"
                    ></v-text-field>
                </div>
                <div class="d-sm-flex justify-space-between ga-4 mt-4">
                    <v-autocomplete
                        v-model="transport_order.cargo_type"
                        density="compact"
                        :items="cargoTypes"
                        hide-details
                        variant="outlined"
                    >
                        <template v-slot:label>
                            Type <span class="text-red">*</span>
                        </template>
                    </v-autocomplete>
                    <v-text-field
                        v-model="transport_order.cargo_qty"
                        class="my-4 my-sm-0"
                        density="compact"
                        variant="outlined"
                        hide-details
                        label="Quantity"
                    ></v-text-field>
                    <v-text-field
                        v-model="transport_order.cargo_weight"
                        density="compact"
                        variant="outlined"
                        hide-details
                        label="Total Weight"
                    ></v-text-field>
                </div>
            </v-card-text>
        </v-card>

        <!-- FILE UPLOAD -->
        <v-card class="mt-4">
            <div class="d-flex w-100 items-center justify-center text-center">
                <div class="w-100 pa-5">
                        <input type="file" multiple id="fileHandler" v-show="false" @change="onChange" ref="file"/>
                        <label for="fileHandler" class="file-uploader">
                            <div>Click here to upload files <span class="text-red">*</span></div>
                        </label>
                        <ul class="mt-4" v-if="fileList.length > 0" style="list-style: none;">
                            <li class="text-caption pa-1" v-for="(file, index) in fileList" :key="index">
                                {{file.name}}
                                <v-btn
                                    class="ml-4"
                                    variant="flat"
                                    size="small"
                                    color="black"
                                    @click="remove(index)"
                                >remove</v-btn>
                            </li>
                        </ul>
                </div>
            </div>
        </v-card>

        <!-- NOTES -->
        <v-card class="pa-4 mt-4">
            <v-textarea
                v-model="transport_order.notes"
                density="compact"
                variant="outlined"
                hide-details
                label="Notes"
            ></v-textarea>
        </v-card>

        <!-- BUTTONS -->
        <v-row class="mt-4">
            <v-col cols="12" sm="6">
                <v-btn
                    class="w-100"
                    color="success"
                    variant="outlined"
                    @click="addTransportOrder"
                >create</v-btn>
            </v-col>
            <v-col cols="12" sm="6">
                <v-btn
                    class="w-100"
                    color="red"
                    variant="outlined"
                    @click="() => router.push(`/${TRANSPORT_ORDERS_ROUTE}`)"
                >Cancel</v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<style scoped>
.file-uploader {
    display: block;
    width: 100%;
    height: 50px;
    cursor: pointer;
}
</style>